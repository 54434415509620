.route-login {
  .row {
    min-height: 100vh;
  }
  .brand {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('images/fsh-logo-with-sign.png');
    background-size: contain;
    height: 4rem;
    margin-bottom: $grid-gutter-width / 2;
  }
  .box {
    padding: $grid-gutter-width / 1.5;
  }
  .btn {
    background-color: black;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
