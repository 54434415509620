.top-nav {
  background-color: white;
  border-bottom: 1px solid #cfd8dc;
  @include media-breakpoint-up(md) {
    display: none;
  }

  .navbar-toggler {
    border: none;
  }

  .user {
    vertical-align: middle;
    display: flex;
    margin: $grid-gutter-width / 2 $grid-gutter-width / -2;
    padding: $grid-gutter-width / 2;
    border-top: 1px solid #cfd8dc;
    border-bottom: 1px solid #cfd8dc;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    .btn {
      margin-left: $grid-gutter-width / 2;
      padding: 0.15rem 0.5rem;
      border-color: #cfd8dc;
      font-size: 0.9rem;
    }
  }

  .navbar-brand {
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url('images/fsh-logo-with-sign.png');
    background-size: contain;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 100%;
    width: 11rem;
  }

}
