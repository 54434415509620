.box {
  border: 1px solid #cfd8dc;
  background-color: white;
  margin-bottom: $grid-gutter-width / 2;
  border-radius: $border-radius;
  box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
  .box-body {
    padding: $grid-gutter-width / 1.5;
  }
  .box-header {
    padding: $grid-gutter-width / 4 $grid-gutter-width / 1.5;
    border-bottom: 1px solid #cfd8dc;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 3 $grid-gutter-width / 1.5;
    }
    h3 {
      margin: 0;
      text-transform: uppercase;
      display: inline-block;
      font-size: 0.9em;
      font-weight: bold;
      opacity: 0.5;
    }
  }
  .box-empty {
    text-align: center;
    padding: $grid-gutter-width;
    em {
      opacity: 0.35;
    }
  }
  .data-table {
    margin-bottom: 0;
    font-size: 0.9rem;
    th, td {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    thead {
      tr {
        th, td {
          border-width: 0 0 1px;
          border-bottom-style: solid;
          border-bottom-color: $body-bg;
          vertical-align: middle;
        }
        th {
          white-space: nowrap;
          text-transform: uppercase;
          color: rgba(black, .25);
          padding: 1rem 0.5rem;
        }
        td {
          padding: 0.5rem;
        }
      }
    }
    tbody {
      tr {
        &:first-child {
          td,
          th {
            border: none;
          }
        }
        td,
        th {
          border-top-color: rgba($table-border-color, 0.5);
        }
      }
      & + tbody {
        border-top: 1px solid rgba($table-border-color, 0.5);
      }
    }
  }
}
