.box-nav {
  margin-bottom: $grid-gutter-width / 2;
  .nav {
    &.nav-pills {
      .nav-link {
        padding: 0.25rem 1rem;
        color: rgba($primary, 0.65);
        transition: all .3s;
        border-radius: 0;
        font-size: 0.9rem;
        background-color: white;
        border: 1px solid $primary;
        text-align: center;
        min-width: 6rem;
        &:hover {
          color: $primary;
        }
        &.active {
          color: white;
          background-color: $primary;
        }
        &:focus,
        &:focus:active {
          outline: none;
        }
      }
      li {
        &:first-child .nav-link {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:last-child .nav-link {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
        & + li {
          margin-left: -1px;
        }
      }
    }
  }
}
