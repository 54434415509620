@media print {
   .layout {
     .side-nav {
       display: none;
       & + div {
         flex: 0 0 100%;
         max-width: 100%;
       }
     }
   }
   .route-inspection-detail {
     .layout-header {
       border: none;
       h1 {
         color: black;
         span {
           color: black;
         }
         .type {
           color: black;
           font-size: inherit;
           margin: 0;
           padding: 0;
           background-color: transparent;
         }
       }
       h1 + a,
       .btn {
         display: none;
       }
     }
     .box {
       page-break-inside: avoid;
       .box-header {
         h3 {
           opacity: 1;
         }
       }
     }
     .data-table {
       .status {
         color: black;
         padding: 0;
         text-align: left;
         background-color: transparent;
         .fa {
           display: none;
         }
       }
     }
     .box-inspection-report {
       page-break-inside: avoid;
       .box-header {
         h3 {
           small {
             color: black;
           }
         }
       }
       .data-table {
         tr {
           &.disabled {
             color: black;
           }
         }
       }
       .comment {
         background-color: transparent;
         border: 1px solid rgba(black, 0.15);
         .author {
           opacity: 1;
           font-weight: bold;
         }
       }
     }
     .gallery-header,
     .box-photos {
       display: none;
     }
     .report {
       .col-md-6 {
         flex: 0 0 33.33333%;
         max-width: 33.33333%;
       }
     }
     .col-xl-4 {
       flex: 0 0 33.33333%;
       max-width: 33.33333%;
     }
     .col-xl-8 {
       flex: 0 0 66.66667%;
       max-width: 66.66667%;
     }
   }
}
