.form-group-camera {
  .photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 $grid-gutter-width / -4;
    .photo {
      padding: 0 $grid-gutter-width / 4;
      .image {
        background-position: center;
        background-size: cover;
        margin-bottom: $grid-gutter-width / 2;
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
        width: 8rem;
        height: 8rem;
        position: relative;
      }
      .photo-actions {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black, .75);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn {
          margin: 0;
          min-width: 6rem;
          & + .btn {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  .btn {
    margin-top: $grid-gutter-width / 2;
  }
}
