.ReactTable {
  font-size: 0.9em;
  .rt-table {
    .rt-thead {
      &.-header {
        box-shadow: none;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        background-color: #F7F9FB;
      }
      .rt-th,
      .rt-td {
        &.-sort-asc {
          box-shadow: inset 0 3px 0 0 $primary;
        }
        &.-sort-desc {
          box-shadow: inset 0 -3px 0 0 $primary;
        }
      }
      .rt-th {
        font-weight: bold;
      }
    }
    .rt-tbody {
      .rt-tr {
        align-items: center;
        &.-even {
          background-color: #F4F6F9;
        }
      }
      .rt-td {
        &.rt-td-dropdown {
          overflow: visible;
          .dropdown-toggle {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}
.table-toolbar {
  .table-search {
    max-width: 260px;
  }
  .col > div {
    padding: $grid-gutter-width / 2;
  }
  .dropdown {
    display: inline-block;
    & + .dropdown,
    & + .btn {
      margin-left: $grid-gutter-width / 4;
    }
    .dropdown-toggle {
      &:after {
        vertical-align: 0.1em;
      }
    }
    .dropdown-menu {
      font-size: 0.9rem;
    }
    .fa-fw {
      margin-right: 0.5rem;
    }
    .fa-toggle-on {
      color: $success;
    }
    .fa-toggle-off {
      color: rgba($body-color, 0.25);
    }
  }




}

.pagination-bottom {
  border-top: solid 1px rgba(0,0,0,0.05);
  .Table__itemCount {
    font-size: 15px;
  }
  .Table__pagination {
    display: flex;
    justify-content: center;
  }
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-right: solid 1px rgba(0,0,0,0.05);
    padding: 15px;
    min-width: 60px;
  }
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  .Table__pageButton--active {
    color: $primary;
    font-weight: bold;
  }
  .Table__nextPageWrapper {
    .Table__pageButton {
      border-right: none;
    }
  }
}
