.layout {
  margin-top: 56px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
  main {
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    h5 {
      margin-top: $grid-gutter-width;
      .toggle-group {
        margin-left: 1rem;
        vertical-align: middle;
        height: 18px;
        .toggle-label {
          font-weight: normal;
          font-size: 0.85rem;
          margin-bottom: 1px;
          margin-left: 5px;
          vertical-align: text-top;
          opacity: 0.75;
        }
        .toggle {
          vertical-align: text-top;
        }
      }
    }
  }
}

.layout-header {
  display: flex;
  flex-direction: column;
  margin: 0 $grid-gutter-width / -2 $grid-gutter-width / 2;
  padding: 0 $grid-gutter-width / 2 $grid-gutter-width / 2;
  border-bottom: 1px solid #cfd8dc;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
  }
  & > div {
    & + div {
      margin-top: 1rem;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }
  h1 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0.85;
    line-height: 1;
    & + a {
      font-size: 0.9rem;
      transition: all .3s;
      color: rgba(black, 0.5);
      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }
    span {
      color: $primary;
    }
    .type {
      color: white;
      font-size: 1.25rem;
      margin-right: 0.5rem;
      vertical-align: text-top;
    }
  }
  .btn {
    & + .btn {
      margin-left: $grid-gutter-width / 4;
    }
  }
  .nav {
    &.nav-pills {
      margin-top: $grid-gutter-width / 6;
      .nav-link {
        padding: 0.25rem 1rem;
        color: $primary;
        transition: all .3s;
        border-radius: 0;
        font-size: 0.9rem;
        background-color: white;
        border: 1px solid $primary;
        &:hover {
          color: $primary;
        }
        &.active {
          color: white;
          background-color: $primary;
        }
      }
      li {
        &:first-child .nav-link {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:last-child .nav-link {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
}
