.box-pie-chart {
  .legend {
    font-size: 0.8rem;
    line-height: 0.8rem;
    background-color: white;
    margin-top: -2rem;
    position: relative;
    min-height: 2rem;
    .color {
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      vertical-align: bottom;
      margin-right: 0.5rem;
    }
    .values {
      float: right;
    }
    & > div {
      & + div {
        margin-top: 0.5rem;
      }
    }
  }
}
