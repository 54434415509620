.box-reports {
  border: none;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  h5 {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .report {
    border: 1px solid #cfd8dc;
    background-color: white;
    margin-bottom: $grid-gutter-width / 4;
    border-radius: $border-radius;
    box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);

    display: flex;
    flex-direction: row;
    align-items: center;
  //  justify-content: space-between;

    .symbol {
      text-align: center;
      padding: $grid-gutter-width / 4;
      border-right: 1px solid #cfd8dc;
      font-size: 1.25rem;
    }
    .date {
      padding: $grid-gutter-width / 4;
      font-size: 0.9rem;
      opacity: 0.5;
    }
    .right {
      margin-left: auto;
      margin-right: $grid-gutter-width / 4;
    }
    strong {
      padding: $grid-gutter-width / 4;
    }

    &.passed {
      .symbol {
        background-color: $success;
        color: white;
      }
      strong span {
        color: $success;
      }
    }
    &.pending {
      .symbol {
        background-color: #cfd8dc;
        color: white;
      }
      strong span {
        color: #cfd8dc;
      }
    }
    &.failed {
      .symbol {
        background-color: $danger;
        color: white;
      }
      strong span {
        color: $danger;
      }
    }
  }

}
