.type {
  font-weight: bold;
  font-size: 0.85rem;
  display: inline-block;
  text-align: right;
  line-height: 1.2;
  background-color: $primary;
  color: white;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  &.blue {
    background-color: $primary;
  }
  &.green {
    background-color: $success;
  }
  &.black {
    background-color: black;
  }
}
