.box-map {
  position: relative;
  border: 1px solid #cfd8dc;
  background-color: white;
  box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
  margin-bottom: $grid-gutter-width / 2;
  min-height: 260px;
  height: 30vh;
  @include media-breakpoint-up(lg) {
    min-height: 330px;
  }
  @include media-breakpoint-up(lg) {
    min-height: 400px;
  }
  &.box-map-square {
    position: relative;
    min-height: 0;
    height: 0;
    padding-bottom: 100%;
    & > div {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
