.form-label {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  &.required {
    &:after {
      content: '*';
      color: $danger;
      display: inline-block;
      margin-left: 2px;
    }
  }
}

.form-group-spacing {
  margin-top: $grid-gutter-width * 1.15;
}
