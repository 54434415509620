.box-photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 $grid-gutter-width / -4;
  .photo {
    padding: 0 $grid-gutter-width / 4;
    .image {
      background-position: center;
      background-size: cover;
      margin-bottom: $grid-gutter-width / 2;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      width: 12rem;
      height: 12rem;
      position: relative;
    }
    &.small {
      .image {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
