.file-upload {
  .btn-file-upload {
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}
