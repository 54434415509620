.side-nav {
  display: none;
  min-height: 100vh;
  background-color: white;
  border-right: 1px solid #cfd8dc;
  @include media-breakpoint-up(md) {
    display: block;
  }

  aside {
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
  }

  .navbar-brand {
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url('images/fsh-logo-with-sign.png');
    background-size: contain;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 100%;
    display: block;
    margin: 0;
    height: 3rem;
  }

  .nav-link {
    position: relative;
    color: rgba(#404040, .7);
    transition: all .3s;
    padding: 0.25rem 0.5rem;
    &:hover {
      color: #000;
    }
    &.active {
      color: $primary;
      &:before {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: $grid-gutter-width / -2;
        width: 5px;
        background-color: $primary;
        content: '';
      }
    }
    &.failure {
      color: $danger;
      &.active {
        color: $danger;
        &:before {
          background-color: $danger;
        }
      }
      &:hover {
        color: #000;
      }
    }
    .badge {
      float: right;
      margin-top: 2px;
    }
  }

  .user {
    vertical-align: middle;
    display: flex;
    margin: $grid-gutter-width / 2 $grid-gutter-width / -2;
    padding: $grid-gutter-width / 2;
    border-top: 1px solid #cfd8dc;
    border-bottom: 1px solid #cfd8dc;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    .btn {
      margin-left: $grid-gutter-width / 2;
      padding: 0.15rem 0.5rem;
      border-color: #cfd8dc;
      font-size: 0.9rem;
    }
  }

}
