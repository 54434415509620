.box-inspections-list {
  margin-bottom: $grid-gutter-width / 2;

  h5 {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .inspections-list-container {
    border-radius: $border-radius;
    border: 1px solid #cfd8dc;
    background-color: rgba(black, 0.05);
    padding: $grid-gutter-width / 3;
    height: 40vh;
    overflow-y: auto;
    position: relative;
    .empty-list {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      opacity: .35;
    }
  }

  .inspection-assign-card {
    border: 1px solid #cfd8dc;
    background-color: white;
    padding: $grid-gutter-width / 2;
    box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
    & + .inspection-assign-card {
      margin-top: $grid-gutter-width / 3;
    }
    address {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > div + div {
        text-align: right;
      }
    }
    .builder {
      font-size: 0.8rem;
      color: rgba($body-color, 0.5);
    }
    .date {
      font-weight: bold;
      display: block;
      text-align: right;
      line-height: 1.2;
    }
    .type {
      font-weight: bold;
      font-size: 0.85rem;
      display: inline-block;
      text-align: right;
      line-height: 1.2;
      background-color: $primary;
      color: white;
      border-radius: 3px;
      padding: 0.2rem 0.5rem;
      &.blue {
        background-color: $primary;
      }
      &.green {
        background-color: $success;
      }
      &.black {
        background-color: black;
      }
    }
    .qat-select {
      margin-top: 0.5rem;
      min-width: 12rem;
      text-align: left;
      .form-label {
        margin: 0.75rem 0 0.25rem;
      }
      .form-group {
        margin: 0;
      }
    }
    .qat-date {
      margin-top: 0.5rem;
      width: 9rem;
      text-align: left;
      .form-label {
        margin: 0.75rem 0 0.25rem;
      }
      .form-group {
        margin: 0;
      }
    }
  }

}
