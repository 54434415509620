.scroll-box {
  position: relative;
  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, transparent, white 90%);
    z-index: 1;
    height: $grid-gutter-width * 0.75;
    @include media-breakpoint-up(md) {
      height: $grid-gutter-width * 0.5;
    }
  }
  .scroll {
    overflow-y: auto;
    margin: 0 $grid-gutter-width / -2 $grid-gutter-width / -2;
    padding: 0 $grid-gutter-width / 2;
    @include media-breakpoint-up(md) {
      margin: 0 $grid-gutter-width / -3 $grid-gutter-width / -3;
      padding: 0 $grid-gutter-width / 3;
    }
    & > div {
      padding-bottom: $grid-gutter-width / 2;
      @include media-breakpoint-up(md) {
        padding-bottom: $grid-gutter-width / 3;
      }
    }
  }
}
