.status {
  border-radius: 4px;
  padding: 3px 8px;
  text-align: center;
  font-size: 0.9rem;
  &.green {
    color: white;
    background-color: $success;
  }
  &.red {
    color: white;
    background-color: $danger;
  }
  &.orange {
    color: white;
    background-color: $warning;
  }
  &.blue {
    color: white;
    background-color: $primary;
  }
  &.gray {
    color: black;
    background-color: #ccc;
  }
}
