.box-inspection-report {
  &.failed,
  &.failed .box-header {
    border-color: $danger;
  }
  .box-header {
    &.failed {
      color: $danger;
    }
    h3 {
      opacity: 1;
      display: block;
      .fa, .fal, small {
        float: right;
      }
      small {
        color: rgba(black, 0.35);
        font-size: 100%;
      }
    }
  }
  .data-table {
    margin: -10px 0;
    tr {
      &.failed {
        color: $danger;
      }
      &.disabled {
        color: rgba(black, 0.35);
      }
    }
    td {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      strong {
        font-size: 0.9rem;
      }
      .btn {
        padding: 0.25rem 0.35rem;
        font-size: 0.8rem;
        vertical-align: middle;
        line-height: 1;
      }
    }
    th, td {
      padding: 0.5rem;
    }
    .comment {
      display: block;
      font-weight: normal;
      font-size: 0.8rem;
      position: relative;
      border-radius: 3px;
      padding: 10px;
      background-color: $body-bg;
      max-width: 100%;
      margin-top: 5px;
      color: black;
      .author {
        font-size: 0.7rem;
        opacity: .5;
      }
    }
  }
}
