.box-smart-table {

  .status {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8rem;
    display: block;
    text-align: center;
    padding: 0 0.25rem;
    &.red {
      color: white;
      background-color: $danger;
    }
    &.green {
      color: white;
      background-color: $success;
    }
    &.orange {
      color: white;
      background-color: $warning;
    }
    &.blue {
      color: white;
      background-color: $primary;
    }
    &.gray {
      color: black;
      background-color: #ccc;
    }
  }

}
