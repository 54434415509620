$icon-font-path:      "../../node_modules/bootstrap/fonts/";
$fa-font-path:        "../../node_modules/@fortawesome/fontawesome-pro/webfonts";

$font-family-sans-serif:      "Roboto", Arial, sans-serif;

$body-bg:                     #f3f3f3;
$primary:                     #465aca;

/*

$danger:                             #b34d4d;
$success:                            #5eb34d;
$info:                               #4d80b3;
*/
