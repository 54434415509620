.react-calendar {

  .react-calendar-header {

    .react-calendar-header-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $grid-gutter-width / 2;
      .react-calendar-header-label {
        h2 {
          margin: 0;
          line-height: 1;
          font-size: 1.5rem;
        }
      }
      .react-calendar-header-actions {
        .btn {
          margin-left: 0.25rem;
          padding: 0.15rem 0.75rem;
        }
      }
      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .react-calendar-legend {
      display: flex;
      flex-direction: row;
      & > div {
        flex: 1;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

  }

  .react-calendar-card {
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    display: inline-flex;
    background-color: $primary;
    color: white;
    border: 1px solid $primary;
    padding: 2px 5px 0;
    font-weight: 400;
    font-size: 0.9rem;
    &:hover {
      width: auto;
      box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
    }
    .fa, .fal {
      position: absolute;
      font-size: 1.5rem;
      right: 0.8rem;
      top: 0.8rem;
      opacity: 0.45;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
    &.black {
      background-color: black;
      border-color: black;
    }
  }

  .react-calendar-cell-headers {
    display: flex;
    border-top: 1px solid #cfd8dc;
    & > div {
      flex: 1;
      text-align: center;
      font-weight: bold;
      font-size: 0.8rem;
      text-transform: uppercase;
      padding: 0.25rem 0;
      & + div {
        border-left: 1px solid #cfd8dc;
      }
    }
  }

  .react-calendar-rows {
    position: relative;
  }

  .react-calendar-cell {
    flex: 1;
    height: 45vh;
    min-height: 200px;
    border-top: 1px solid #cfd8dc;
    & + .react-calendar-cell {
      border-left: 1px solid #cfd8dc;
    }
    &.is-today {
      background-color: rgba($primary, 0.05);
      & > div {
        .date-label {
          color: $primary;
        }
      }
    }
    & > div {
      position: relative;
      .date-label {
        font-size: 1rem;
        padding: 0.25rem;
        opacity: 0.35;
      }
    }
  }

}
